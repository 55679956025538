import { ServiceOneData } from "@/data";
import Link from "@/components/link";
import React from "react";
import Img from "@/components/img";
import { FadeInImage, FadeInRegular } from "@/components/gsap/gsapComponents";


const ServiceOne = () => {
  const { title, posts } = ServiceOneData;
  return (
    <section className="service_area section_padding" id="servicesArea">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="hero-section-title text-center">
              <h1>{title}</h1>
              <p>
                Elina's Cleaning Service, a Northern Territory owned and operated business, has been
                one of Top End's top commercial cleaning companies. Over the time, we have built an
                enviable reputation for quality and efficiency and are well known for being able to
                get projects "over the line". Attention to detail is critical in commercial cleaning
                and the Elina's team pride themselves on their impeccable cleaning quality which is
                always delivered on time. From initial scoping through to the actual cleaning and
                post project follow up, our customer service is second to none. Our management team
                is active in every project to ensure that clients are satisfied.{" "}
              </p>
            </div>
          </div>
          {posts.map(({ image, icon, title, text, button }, index) => (
            <FadeInImage>
            <div className="col-lg-4 col-md-6" key={`service-one-key-${index}`}>
              <div className="service_box">
                <div className="service_img">
                  <Img
                    src={image}
                    alt="service 1"
                    className="img-fluid"
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="service_details">
                  <h2>{title}</h2>

                  <p>{text}</p>
                </div>
              </div>
            </div>
            </FadeInImage>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceOne;
